<single-column [talk]="false">
    <div class="text-center" *ngIf="isValid && !isComplete">
        <h1 class="sprk-b-TypeDisplayOne">We’re verifying your email address right now.</h1>
        <img src="/assets/img/Email-Unverified.svg" class="center-block" alt="Unverified Email">
    </div>
    <div class="text-center" *ngIf="isValid && !emailVerified && timeValid && isComplete">
        <ng-container [ngSwitch]="verifyType">
            <ng-container *ngSwitchCase="'createAccount'">
                <h1 class="sprk-b-TypeDisplayOne">Success! <br>Your account is all set up.</h1>
                <img src="/assets/img/Email-Success.svg" class="center-block" alt="Verified Email">
                <rckt-button type="primary" buttonStyle="block" data-analytics-click="button:Verified Email Continue" (click)="goToRedirect()" id="verify-continue">Continue</rckt-button>
            </ng-container>
            <ng-container *ngSwitchCase="'usernameUpdate'">
                <h1 class="sprk-b-TypeDisplayOne">You've successfully changed your username.</h1>
                <p>Now, let's get you signed in.</p>
                <img src="/assets/img/Email-Success.svg" class="center-block" alt="Username Changed">
                <rckt-button type="primary" buttonStyle="block" (click)="goToRedirect()" id="verify-continue">Go to Sign-In</rckt-button>
            </ng-container>
        </ng-container>
    </div>
  <div class="text-center" *ngIf="!isValid && expired">
    <h1 class="sprk-b-TypeDisplayOne">Invalid or expired verification link.</h1>
    <p>Please request a new verification email.</p>
    <img src="/assets/img/Email-Error.svg" class="center-block" alt="Email Error">
  </div>
  <div class="text-center" *ngIf="!isValid && genericError">
    <h1 class="sprk-b-TypeDisplayOne">Generic Error</h1>
    <p>Uh oh! Something isn't working. Please try again or please <a routerLink="/talk-to-us">contact us</a>.</p>
    <img src="/assets/img/Email-Error.svg" class="center-block" alt="Email Error">
  </div>
</single-column>
