import {Component, OnInit} from '@angular/core';
import {PostTokenService} from 'app/services/logical/post-token.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AnalyticsService} from 'app/services/generic/analytics.service';
import { HttpService } from 'app/services/logical/create-account.service';

@Component({
  selector: 'app-email-confirm',
  templateUrl: './email-verify.component.html',
  styleUrls: ['./email-verify.component.scss'],
  providers: [PostTokenService]
})
export class EmailVerifyComponent implements OnInit {

  postData: string;
  isValid = true;
  isComplete = false;
  timeValid = true;
  verifyType: string;
  redirect: string;
  timestamp: any;
  emailVerified = false;
  genericError = false;
  expired = false;
  emailResent = false;

  constructor(
    private postToken: PostTokenService,
    private route: ActivatedRoute,
    private analytics: AnalyticsService,
    private httpService: HttpService,
    private _analytics: AnalyticsService,
    private router: Router    ) {}

  ngOnInit() {
    this._analytics.pageViewEvent("Email Verification");
    let token;

    if (this.route.snapshot.paramMap.get('token')) {
      token = this.route.snapshot.paramMap.get('token');
      this.verifyType = 'createAccount';
    } else {
      token = this.route.snapshot.paramMap.get('username-token');
      this.verifyType = 'usernameUpdate';
    }

    this.postVerification(token);

    if (this.route.snapshot.queryParams['redirect']) {
      this.httpService.redirect = atob(this.route.snapshot.queryParams['redirect']);
    }
  }

  postVerification(token) {
    this.postToken.postTokenCreate(token, this.verifyType)
      .subscribe(
        data => this.postData = JSON.stringify(data),
        error => {
          if (error.status === 404 || error.status === 400) {
            this.expired = true;
            this.isValid = false;
          }
          else {
            this.genericError = true;
            this.isValid = false;
          }
        },
        () => {
          this.isComplete = true;
          sessionStorage.setItem('email-verified', 'true');
          this.analytics.track('email_verify');
        }
      );
  }

  goToRedirect() {
    if (this.httpService.redirect) {
      // check for mobile redirect domains
      if (this.httpService.redirect.startsWith('https://rockethq.onelink.me') ||
          this.httpService.redirect.startsWith('https://rocketmortgage.onelink.me')) {
          window.location.assign(this.httpService.redirect);
      } else {
          // persist the redirect, go to sign in to end the session, and force the user to sign in again to
          // update emailVerify status in the token
          sessionStorage.setItem('redirect', this.httpService.redirect);
          this.router.navigate(['sign-in']);
      }
    } else {
      this.router.navigate(['sign-in']);
    }
  }

  resendVerificationEmail() {
    this.emailResent = true;
  }
}
